import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'
import Contact from '../components/Contact'
import Helmet from 'react-helmet'
import get from 'lodash/get'

import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

class SEOPageTemplate extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const SEOPage = this.props.data.contentfulSeoPage
    const pageDescription = SEOPage.pageDescription.childMarkdownRemark.html
    return (
      <Layout location={this.props.location}>
        <Helmet title={`${SEOPage.pageTitle} | ${siteTitle}`} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10 section text-center">
              <h1 className="section--title work-title mb-5">
                {SEOPage.pageTitle}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: pageDescription }} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Gallery images={SEOPage.images} workTitle={SEOPage.pageTitle} />
            </div>
          </div>
        </div>
        <Contact />
      </Layout>
    )
  }
}

export default SEOPageTemplate

export const pageQuery = graphql`
  query SEOPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSeoPage(slug: { eq: $slug }) {
      pageTitle
      pageDescription {
        childMarkdownRemark {
          html
        }
      }
      slug
      images {
        description
        file {
          url
        }
      }
    }
  }
`
